import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"
import RelatedProjects from "Layout/Projects/RelatedProjects/RelatedProjects"
import Helmet from "react-helmet"
import config from "../../../data/SiteConfig"

const ResearchTrackTemplate = ({
  data,
  location
}) => {

  const {page, projects, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  const researchTrackProjects = projects.edges.filter(project => {
    return project.node.acf.project.project_research_track && project.node.acf.project.project_research_track.post_title && project.node.acf.project.project_research_track.post_title === page.title
  })

  return (
    <MainLayout location={location}>
      <Helmet title={`${page.title} | ${config.siteTitle}`} />
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
      <RelatedProjects projects={researchTrackProjects} headline={`${page.title} Projects`} />
    </MainLayout>
  )
}

export const query = graphql`
  query researchSingleQuery($id: String!) {
    page: wordpressWpResearchTracks(id: { eq: $id }) {
      title
      slug
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
      }
    }
    projects: allWordpressWpProjects {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            localFile {
              childImageSharp {
                fixed(width: 684) {
                  src
                  width
                  height
                  originalName
                }
              }
            }
          }
          acf {
            project {
              project_research_track {
                post_title
              }
            }
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ResearchTrackTemplate
